<template>
  <div class="col school_dashboard_page_wrapper" v-loading="loadingValue">
    <div @click="printPage" class="sharing_wrapper mb-3">
      <span class="">
        {{ $t("admin_dashboard_type.print_report") }}
      </span>
      <i class="fa fa-share-alt print_now"></i>
    </div>
    <card>
      <div class="some_details">
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.classes") }}
          </span>
          <span class="d-block w-100">{{ cardsData.count_of_groups }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i
            class="fa fa-exclamation icon not_active"
            v-if="cardsData.in_active_classes != 0"
          ></i>
          <i
            class="fa fa-check icon active"
            v-if="cardsData.in_active_classes == 0"
          ></i>
          <span class="d-block w-100 title"
            >{{ $t("admin_dashboard_type.in_active_classes") }}
          </span>
          <span class="d-block w-100">{{ cardsData.in_active_classes }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="w-100 title">{{
            $t("admin_dashboard_type.max_seats")
          }}</span>
          <span
            class="w-100 d-flex justify-content-center used_seats"
            :class="{ direction_reverse: whichLanguage != 'ar' }"
          >
            <span>{{ cardsData.max_seats }}</span>
            <span>/</span>
            <span>{{ cardsData.count_of_students }}</span>
          </span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.count_of_teachers") }}
          </span>
          <span class="d-block w-100">{{ cardsData.count_of_teachers }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">{{
            $t("admin_dashboard_type.count_of_students")
          }}</span>
          <span class="d-block w-100">{{ cardsData.count_of_students }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i class="fa fa-check icon active"></i>
          <span class="d-block w-100 title">{{
            $t("admin_dashboard_type.avg_completed_lessons")
          }}</span>
          <span class="d-block w-100">{{
            cardsData.avg_completed_lessons
          }}</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">{{
            $t("admin_dashboard_type.count_of_activities")
          }}</span>
          <span class="d-block w-100">{{ cardsData.count_of_activities }}</span>
        </div>
        <div class="every_part shadow-sm">
          <i
            class="fa fa-exclamation icon not_active"
            v-if="cardsData.pending_activities != 0"
          ></i>
          <i
            class="fa fa-check icon active"
            v-if="cardsData.pending_activities == 0"
          ></i>
          <span class="d-block w-100 title">{{
            $t("admin_dashboard_type.pending_activities")
          }}</span>
          <span class="d-block w-100">{{ cardsData.pending_activities }}</span>
        </div>
      </div>
    </card>

    <!-- Start Charts  -->
    <card>
      <div class="row">
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">{{
            $t("admin_dashboard_type.levels")
          }}</span>
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="450"
              :options="chartTwoForLevels.chartOptions"
              :series="chartTwoForLevels.series"
            ></apexchart>
          </div>
        </div>
        <div class="col-md-6 mb-4 mt-4">
          <span class="d-block chart_title">{{
            $t("admin_dashboard_type.count_of_students")
          }}</span>
          <div class="chart_container">
            <apexchart
              height="350"
              type="pie"
              width="400"
              :options="chartTwoForGenders.chartOptions"
              :series="chartTwoForGenders.series"
            ></apexchart>
          </div>
        </div>
      </div>
    </card>
    <!-- Start Charts  -->
    <!-- Start Details  -->
    <server-table
      :DataItems="schoolAdminReport"
      :count="schoolAdminReportCount"
      :useFilter="false"
      :enable_export="false"
      :action="false"
      @Refresh="Refresh"
    >
      <template #columns>
        <el-table-column
          prop="group_name"
          :label="$t('admin_dashboard_type.class')"
          min-width="90"
        >
          <template slot-scope="scope">
            <router-link
              class="hide_when_print"
              :to="`/school-dashboard/${scope.row.id}`"
            >
              {{ scope.row.group_name }}
            </router-link>
            <span class="display_when_print">
              {{ scope.row.group_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('Global.status')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 0"
              round
              size="small"
              type="danger"
            >
              {{ $t("non-active") }}
            </el-button>
            <el-button
              v-if="scope.row.status == 1"
              round
              size="small"
              type="primary"
            >
              {{ $t("active") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          :label="$t('admin_dashboard_type.teacher')"
          min-width="110"
        >
        </el-table-column>
        <el-table-column
          prop="students_count"
          :label="$t('admin_dashboard_type.students_number')"
        >
        </el-table-column>
        <el-table-column prop="grade" :label="$t('admin_dashboard_type.level')">
          <template slot-scope="scope">
            <span>
              {{ scope.row.grade ? `Grade ${scope.row.grade}` : "__" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avg_completed_lessons"
          :label="$t('admin_dashboard_type.avg_completed_lessons')"
        >
        </el-table-column>
        <el-table-column
          min-width="90px"
          prop="avg_completed_activities"
          :label="$t('admin_dashboard_type.completed_lessons')"
        >
        </el-table-column>
        <el-table-column
          prop="count_of_pre_exam_one_students"
          :label="$t('admin_dashboard_type.pre_one')"
        >
        </el-table-column>
        <el-table-column
          prop="count_of_pre_exam_two_students"
          :label="$t('admin_dashboard_type.pre_two')"
        >
        </el-table-column>
        <el-table-column
          prop="count_of_last_exam_one_students"
          :label="$t('admin_dashboard_type.post_one')"
        >
        </el-table-column>
        <el-table-column
          prop="count_of_last_exam_two_students"
          :label="$t('admin_dashboard_type.post_two')"
        >
        </el-table-column>
      </template>
    </server-table>
    <!-- End Details  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
export default {
  components: { ServerTable },
  data() {
    return {
      chartOne: {
        series: [44, 67, 30, 58],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Grade 1", "Grade 2", "Grade 3", "Grade 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      chartTwo: {
        series: [65, 35],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Male", "Female"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      cardsData: {
        count_of_groups: null,
        in_active_classes: null,
        max_seats: null,
        count_of_teachers: null,
        count_of_students: null,
        avg_completed_lessons: null,
        count_of_activities: null,
        pending_activities: null,
      },
    };
  },
  mounted() {
    this.Refresh();
  },

  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      schoolAdminReport: ["schools/schoolAdminReport"],
      schoolAdminReportCount: ["schools/schoolAdminReportCount"],
      schoolAdminReportLevelsChart: ["schools/schoolAdminReportLevelsChart"],
      schoolAdminReportGendersChart: ["schools/schoolAdminReportGendersChart"],
    }),
    whichLanguage() {
      return this.$i18n.locale;
    },
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },
    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    chartTwoForGenders() {
      let series = [];
      if (this.schoolAdminReportGendersChart.length > 0) {
        this.schoolAdminReportGendersChart.forEach((element) => {
          series.push(element.user_count);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: ["Male", "Female"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
    chartTwoForLevels() {
      let series = [];
      let labels = [];
      if (this.schoolAdminReportLevelsChart.length > 0) {
        this.schoolAdminReportLevelsChart.forEach((element) => {
          series.push(element.user_count);
          labels.push(`Grade ${element.grade}`);
        });
      }
      return {
        series: series,
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          dataLabels: {
            enabled: true,
          },
          labels: labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store
        .dispatch("schools/getSchoolAdminReport", { query: query })
        .then((res) => {
          this.cardsData.count_of_groups = res.count_of_groups;
          this.cardsData.in_active_classes = res.in_active_classes;
          this.cardsData.max_seats = res.max_seats;
          this.cardsData.count_of_teachers = res.count_of_teachers;
          this.cardsData.count_of_students = res.count_of_students;
          this.cardsData.avg_completed_lessons = res.avg_completed_lessons;
          this.cardsData.count_of_activities = res.count_of_activities;
          this.cardsData.pending_activities = res.pending_activities;
        });
    },
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
.used_seats {
  &.direction_reverse {
    flex-direction: row-reverse;
  }
}

.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #fff;

  display: flex;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.display_when_print {
  display: none;
}
@media print {
  // .expand-panel,
  .sharing_wrapper,
  .hide_when_print {
    display: none;
  }
  .display_when_print {
    display: block;
  }
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.chart_title {
  font-weight: bold;
  color: #555;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.chart_container {
  display: flex;
  justify-content: center;
}
.school_dashboard_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      margin-bottom: 25px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
      .icon {
        position: absolute;
        top: 10px;

        color: #fff;
        border-radius: 50%;
        right: 10px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        font-size: 14px;
        padding: 4px;
        &.not_active {
          background-color: #fb404b;
        }
        &.active {
          background-color: #67c23a;
        }
      }
    }
  }
}
</style>
